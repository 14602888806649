/**
@author : Anjali Tandel
@class : Enumeration 
@description :Create enumeration for invalid error message
**/

export enum Image {
    ClientLogo = '/assets/images/dashboard/no_logo.png',
    TriggerLogo = '/assets/images/trigger-sign-in-logo.png'
}

export enum ErrorMessage {
    ClientId = 'Please Enter Client Id.',
    ClientIdInvalid = 'Please Enter Valid Client Id.',
    ClientIdInvalidLength = 'Client Name Should Not Be More Than 100 Characters.',
    ClientNameNull = 'Please enter Company Name.',
    ClientNameInvalid = 'Company Name is not valid.',
    ClientType = 'Please Select Company Type.',
    ClientAddress = 'Please Enter Address line 1.',
    ClientAddress1Invalid = 'Address Line 1 is not valid.',
    ClientAddress2Invalid = 'Address Line 2 is not valid.',
    ClientCity = 'Please Enter City.',
    ClientCityInvalid = 'City is not valid.',
    ClientCityInvalidLength = 'City should be maximum 50 characters long.',
    ClientState = 'Please Enter State.',
    ClientStateInvalid = 'State is not valid.',
    ClientStateInvalidLength = 'State should be maximum 25 characters long.',
    ClientZip = 'Please Enter Zipcode.',
    ClientZipInvalid = 'Zipcode is not valid.',
    ClientZipInvalidLength = 'Zipcode should be maximum 15 characters long.',
    ClientCountry = 'Please Enter Country.',
    ClientCountryInvalid = 'Country is not valid.',
    SelectCountryCode = 'Please Select Code.',
    ClientPhoneNumber = 'Please Enter Phone Number.',
    ClientPhoneNumberInvalid = 'Phone Number must be at least 7 digits.',
    ClientStartDate = 'Please Select Contract Start Date.',
    ClientEndDate = 'Please Select Contract End Date.',
    ClientEndDateInvalid = 'End Date Should Not Be Less Than or Equal to Start Date.',//'Start Date Should Be Less Than End Date.',
    ClientGracePeriod = 'Please Enter Grace Period.',
    GracePeriodInvalid = 'Grace Period is not valid.',
    ClientGracePeriodInvalid = 'Grace Period Should Not Be More Than 365.',
    ClientInactivityInvalid = 'Inactivity Days is not valid.',
    ClientReminderInvalid = 'Reminder Days is not valid.',
    ClientInactivityDaysInvalid = 'Inactivity Days Should Not Be More Than 365.',
    ClientReminderDaysInvalid = 'Reminder Days Should Not Be More Than 365.',
    AzuerProviderClientId = 'Please Enter Provider Client Id.',
    AzuerProviderTenantId = 'Please Enter Provider Tenant Id.',
    AzuerApplicationName = 'Please Enter Application Name.',
    AzuerWebCallBackUrl = 'Please Enter Web Call-back Url.',
    AzuerAndroidCallBackUrl = 'Please Enter Android Call-back Url.',
    AzuerIosCallBackUrl = 'Please Enter IOS Call-back Url.',
    EmployeeId = 'Please Enter Team Member Id.',
    EmployeeIdInvalid = 'Please Enter Valid Team Member Id.',
    EmployeeIdInvalidLength = 'Team Member Id should not be more than 10 characters.',
    FirstName = 'Please Enter First Name.',
    FirstNameInvalid = 'Please Enter Valid First Name.',
    FirstNameLengthInvalid = 'First Name should be maximum 25 characters long.',
    MiddleNameInvalid = 'Please Enter valid middle name.',
    LastName = 'Please Enter Last Name.',
    LastNameInvalid = 'Please Enter Valid Last Name.',
    LastNameLengthInvalid = 'Last Name should be maximum 25 characters long.',
    EmailAddress = 'Please Enter Email Address.',
    EmailAddressInvalid = 'Please Enter valid Email Address.',
    Position = 'Please Enter Position.',
    PositionInvalid = 'Please Enter valid Position.',
    PositionLengthInvalid = 'Position should be maximum 50 characters long.',
    SelectClient = 'Please Select client.',
    SelectStatus = 'Please Select Status.',
    SelectRole = 'Please Select Truevelop Role.',
    SelectManager = 'Please Select Manager.',
    InTime = 'Please Select In-time.',
    OutTime = 'Please Select Out-time.',
    SelectDepartment = 'Please Select Department Name.',
    SelectRegion = 'Please Select Region.',
    LocationName = 'Please Enter valid location name.',
    DateFormat = 'Please Enter Date Of Birth In MM/DD/YYYY Format.',
    DateOfHire = 'Please Select Date Of Hire.',
    DateBeFuture = 'Date Should be Future Date.',
    DateNotBeFuture = 'Date Should Not be Future Date.',
    DateNotBePast = 'Date Should Not be Past Date.',
    DateOfHireFormat = 'Please Enter Date Of Hire In MM/DD/YYYY Format.',
    DateInPositionFormat = 'Please Enter Date In Position In MM/DD/YYYY Format.',
    DateOfLastSalaryIncreaseFormat = 'Please Enter Last Salary Increase Date In MM/DD/YYYY Format.',
    DateInPositionInvalid = 'Date In Position Should Be Greater Than Date Of Hire.',
    LastSalaryIncreaseInvalid = 'Last Salary Increase Should Be Greater Than Date Of Hire.',
    DateOfLAsrIncreaseSalaryInvalid = 'Date Of Last Increase Salary Should Be Greater Than Or Equals to Date In Position.',
    DateOfBirthInvalid = 'Date Of Birth Should Be Less Than Date Of Hire.',
    Department = 'Please Enter Department Name.',
    DepartmentInvalid = 'Please Enter valid Department Name.',
    DepartmentInvalidLength = 'Department Name should not be more than 25 characters.',
    InternalServerError = 'Sorry, please try again. If the issue continues please contact  <u><a href="mailto:support@truvelop.com">support@truvelop.com </a></u>',
    // InternalServerError = 'Something went wrong, please try again.',
    UnauthorizedAccessError = 'You are not authorized to access this page.',
    NoInternetConnection = 'No Internet Connection.',
    NoRecordsFound = 'No Records Found.',
    SelectAtleastOneDepartment = 'Please select at least one department.',
    ReloginAgain = 'Please Relogin Again.',
    PleaseEnterSalary = 'Please enter salary.',
    CityInvalidLength = 'City should be maximum 30 characters long.',
    PermissionChanged = 'You have not been given access to connected services. Please contact your firm administrator for access.',
    FileInvalid = 'Invalid File Format.',
    SizeInvalid = 'File size should be less than 3 MB.',
    EmptyRemark = 'Please enter remark.',
    TeamStartDateNotBePast = 'Start Date Should Not Be Past Date.',
    TeamEndDateInvalid = 'End Date Should Not Be Less Than or Equal to Start Date.',
    EnterSingleValue = 'Please Select File or Enter Cloud URL',
    EnterValidCloudUrl = 'Please Enter a Valid Cloud URL.',
    SelectNewScore = 'Please select new score.',
    TriggerEmployeeUnauthorizedAccess = 'You are not authorized to access request for Evaluate team member.',
    SparkUnauthorizedAccess = 'You are not authorized to access request for Spark.',
    NoPermissionToAccess = 'You have not been given permission to access this request.',
    SelectColumnConfiguration = 'Please select at least one column.',
    SelectOrAddWorkLocation = 'Please add at-least 1 default Work-location.',
}

export enum Encryption {
    TriggerScoreMessage = 'trigger-triggerScore',
    TriggerScoreKey = 'triggerScore',
    TriggerSelectDepartmentMessage = 'trigger-selectedDepartment',
    TriggerSelectDepartmentKey = 'selectedDepartment',
    TriggerSelectYearMessage = 'trigger-selectedYear',
    TriggerSelectYearKey = 'selectedYear',
    TriggerDepartmentObjectMessage = 'trigger-departmentObject',
    TriggerDepartmentObjectKey = 'departmentObject',
    TriggerSidebarMessage = 'trigger-isSidebar',
    TriggerSidebarKey = 'isSidebar',
    TriggerProfilePicMessage = 'trigger-profilePic',
    TriggerProfilePicKey = 'profilePic',
    TriggerPartialClientResponseMessage = 'trigger-partialClientResponse',
    TriggerPartialClientResponseKey = 'partialClientResponse',
    TriggerUserMessage = 'trigger-userData',
    TriggerUserKey = 'userData',
    TriggerCountryCallingCodeMessage = 'Country-calling-code',
    TriggerCountryCallingCodeKey = 'Country-calling-code-key',
    UrlEncryptionKey = 'encryptedKey',
    UrlCiphertext = 'ciphertext',
    TriggerPhoneNumber = 'Phone-number',
    TriggerPhoneNumberKey = 'Phone-number-key',
    TriggerSelectedId = 'trigger-selectedId',
    SelectedId = 'selectedId',
    TriggerUserName = 'trigger-userName',
    UserName = 'userName',
    ActionPermissionMessage = 'trigger-action-permission',
    ActionPermissionKey = 'action-permission',
    Version = 'Version',
    VersionKey = 'Version-key',
    LoginUserMessage = 'login-user',
    LoginUserKey = 'login-user-key',
    ManagerDashboardYearListMessage = 'manager-dashboard-year-list-code',
    ManagerDashboardYearListKey = 'manager-dashboard-year-list-key',
    SparkAnEmployeeMessage = 'spark-an-employee',
    SparkAnEmployeeKey = 'spark-an-employee-key',
    TeamDashboardLoad = 'team-dashboard',
    TeamDashboardLoadKey = 'team-dashboard-key',
    SparkClassficationsCategories = 'spark-classifications-categories',
    SparkClassficationsCategoriesKey = 'spark-classifications-categories-key',
    EmpDashboardRoute = 'empDashboardRoute',
    DashboardRoute = 'dashboardRoute',
    EmpDashboard = 'empDashboard',
    Dashboard = 'dashboard',
    RequestId = 'request-id',
    DeepLink = 'deep-link',
    DeepLinkUnAuthorized = 'deep-link-unAuthorized',
    MobileLink = 'mobile-link',
    DimensionElementKey = 'master-dimension-key',
    SelectedDimensionArray = 'selected-dimension-array',
    SelectedDimensionFilter = 'selected-dimension-filter',
    SelectedDepartment = 'selected-department',
    TriggerThemeMessage = 'trigger-profilePic',
    TriggerThemeKey = 'profilePic',
    WallSparkId = 'spark-id',
    SelectedEmpStatus = 'emp-status',
    ManagerAction = 'Manager-Action',
    ScoreTitle = 'Score-Title',
    FilterElementDataKey = 'filter-element-data',
    SelectedTrending = 'trending-status',
    TeamMemberPageSize = 'Team-Member-Page-Size'
}
export enum Session {
    UnApprovedSpark = 'unApprovedSpark',
    TeamMemberQueryString = 'team-member-queryString'
}