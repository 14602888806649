/**
@author : Mihir Patel
@class : SparkWidgetComponent
@description :SparkWidgetComponent is created for load view spark an employee module.
**/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'trigger-spark-widget',
  templateUrl: './spark-widget.component.html'
})
export class SparkWidgetComponent implements OnInit {
  constructor() {
   }
  ngOnInit() { }
}
